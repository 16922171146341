
/** Summary of the function:  This function is used to add ripple effect on buttons.
        Parameters   : None
        Description: This Function append a span tag on run time with class name ripple-overlay with this it make ripple effect.**/
$(".ripple-effect, .ripple-effect-dark").on("click", function(e) {
  var rippleDiv = $('<span class="ripple-overlay">'),
    rippleOffset = $(this).offset(),
    rippleY = e.pageY - rippleOffset.top,
    rippleX = e.pageX - rippleOffset.left;

  rippleDiv
    .css({
      top: rippleY - rippleDiv.height() / 2,
      left: rippleX - rippleDiv.width() / 2,
      // background: $(this).data("ripple-color");
    })
    .appendTo($(this));

  window.setTimeout(function() {
    rippleDiv.remove();
  }, 800);
});

/** Summary of the function:  This function is used to open and close side menu.

        Parameters   : None

        Description: This Function use to when user click toggle icon then side menu will Open Close for desktop devices
        **/
if ($(window).width() > 1200) {
  $("#menu").click(function() {
    $("body").toggleClass("close-menu");
  });
}

/** Summary of the function:  This function is used to open and close side menu with overlay.

        Parameters   : None

        Description: This Function use to when user click toggle icon then side menu will open with overlay and when user click on overlay then side menu and overlay will close for Mobile, Tab and Ipad Devices    **/
if ($(window).width() <= 1199) {
  $("#menu").click(function() {
    $("body").addClass("open-menu");
  });
  $(".overlay-screen").click(function() {
    $("body").removeClass("open-menu");
  });
}

